import { createUseStyles } from 'react-jss';
import { CustomCiamUiTheme } from '../../../theme';

export default createUseStyles(
  {
    '@keyframes skeleton': {
      from: { opacity: 0.2 },
      to: { opacity: 1 },
    },
    root: {
      background: ({ theme }: { theme: CustomCiamUiTheme }) =>
        theme.backgroundColor,
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      display: 'flex',
    },
    logo: {
      animation: '$skeleton 1s infinite ease-in-out alternate',
    },
  },
  {
    name: 'LoadingScreen',
  },
);
