import * as React from 'react';
import useStyles from './LoadingScreen.styles';
import { useTheme } from 'react-jss';
import { CustomCiamUiTheme } from '../../../theme';
import { AlignLogo } from '../../pages/ErrorPage/AlignLogo';
import { ExocadLogo } from '../../pages/ErrorPage/ExocadLogo';
import { IBusinessUnit } from '../../../utils/businessUnitMappingHelpers/businessUnitMappingHelpers';
import { CssBaseline } from '../CssBaseline';

export interface ILoadingScreenProps {
  businessUnit: IBusinessUnit['type'];
}

const LoadingScreen: React.FC<ILoadingScreenProps> = ({ businessUnit }) => {
  const theme = useTheme<CustomCiamUiTheme>();
  const classes = useStyles({ theme });
  return (
    <CssBaseline>
      <div className={classes.root}>
        <div className={classes.logo}>
          {businessUnit === 'reseller' ? <ExocadLogo /> : <AlignLogo />}
        </div>
      </div>
    </CssBaseline>
  );
};

export default LoadingScreen;
