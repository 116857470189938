export interface IBusinessUnit {
  type: 'dso' | 'reseller' | 'axi' | 'invisalign' | 'itero' | 'tps' | 'service';
}

export const databaseConnectionMap: Record<IBusinessUnit['type'], string> = {
  axi: 'axi-db',
  reseller: 'orion-db',
  dso: 'dso-db',
  invisalign: 'align-db',
  itero: 'itero-db',
  tps: 'align-db',
  service: 'service-account-db',
};

export const parseCallbackUrlForBusinessUnit = (
  url: string,
): IBusinessUnit['type'] | undefined =>
  /dso/.test(url)
    ? 'dso'
    : /reseller/.test(url)
    ? 'reseller'
    : /axi/.test(url)
    ? 'axi'
    : /invisalign/.test(url)
    ? 'invisalign'
    : /itero/.test(url)
    ? 'itero'
    : /tps/.test(url)
    ? 'tps'
    : undefined;

export const clientDisplayNameMap: Record<IBusinessUnit['type'], string> = {
  reseller: 'exocad Partner Portal',
  dso: 'Align Special Markets Portal',
  axi: 'AXI Partner Portal',
  invisalign: 'Invisalign® Doctor Site',
  itero: 'Itero® Partner Portal',
  tps: 'Invisalign® Doctor Site',
  service: 'Service Account',
};
