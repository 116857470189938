import { NextPageContext } from 'next';
import { getSession, signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React from 'react';

import { routes } from '../../../routes';

import { dsoTheme, resellerTheme } from '../../../theme';
import { ThemeProvider } from 'react-jss';
import {
  databaseConnectionMap,
  IBusinessUnit,
  parseCallbackUrlForBusinessUnit,
} from '../../../utils/businessUnitMappingHelpers/businessUnitMappingHelpers';
import { LoadingScreen } from '../../../components/atoms/LoadingScreen';

export default function Signin({
  businessUnit,
}: {
  businessUnit: IBusinessUnit['type'];
}) {
  const { query } = useRouter();
  const { status } = useSession();

  const callbackUrl = (businessUnit ? `/${businessUnit}` : '/').toString();

  const onSignIn = React.useCallback(
    () =>
      signIn(
        businessUnit,
        {
          callbackUrl,
        },
        {
          connection: databaseConnectionMap[businessUnit],
        },
      ),
    [callbackUrl, businessUnit],
  );

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (status === 'unauthenticated') {
        if (!query.error) {
          onSignIn();
        }
      }
    }, 2000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (timeoutId) {
        return clearTimeout(timeoutId);
      }
    };
  }, [callbackUrl, status, onSignIn, query]);

  return (
    <ThemeProvider
      theme={businessUnit === 'reseller' ? resellerTheme : dsoTheme}
    >
      <LoadingScreen businessUnit={businessUnit} />
    </ThemeProvider>
  );
}

export async function getServerSideProps(context: NextPageContext) {
  const { req, query } = context;
  // Check either the query slug (normal navigation) OR the parse from query.callback(on login)
  const businessUnit = parseCallbackUrlForBusinessUnit(
    query.callbackUrl as string,
  );

  const session = await getSession({ req });

  if (!databaseConnectionMap[businessUnit as IBusinessUnit['type']]) {
    return {
      redirect: {
        destination: `/error/404`,
      },
    };
  }

  if (session) {
    return {
      redirect: {
        destination: `${routes.root()}/${businessUnit}`,
      },
    };
  }

  return {
    props: { businessUnit: businessUnit },
  };
}
